<template>
    <div
        class="mb-2"
        :class="{
            'relative pb-2': show,
            'hidden': !show
        }"
        :data-id="activity.id"
    >
        <div v-if="activity.type == 'comment' || activity.type == 'note'">
            <div class="relative flex items-start">
                <div class="relative">
                    <img
                        class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                        :src="userAvatar(activity.created_by)"
                        alt="">
                </div>
                <div class="flex-1 min-w-0 ml-4">
                    <template v-if="activity.details">
                        <div v-if="editing">
                            <editor
                                v-model="content"
                                :mentionables="userNames"
                            />
                            <div class="flex justify-between mt-3 gap-x-3">
                                <btn gray @click="editing = false">
                                    Cancel
                                </btn>
                                <btn class="ml-auto" red @click="deleteActivity(activity, idx)">
                                    Delete
                                </btn>
                                <btn green @click="saveChanges()">
                                    Save
                                </btn>
                            </div>
                        </div>
                        <div
                            v-else
                            class="max-w-full px-4 py-3 prose whitespace-pre-line border rounded-lg"
                            :class="{
                                'text-blue-600 border-blue-300 bg-blue-50': activity.type == 'note' && userName(activity.created_by) == 'AI Assistant',
                                'text-gray-700 bg-white border-gray-300': activity.type == 'comment' && userName(activity.created_by) !== 'AI Assistant',
                                'text-gray-700 bg-yellow-100 border-yellow-400': activity.type == 'note' && userName(activity.created_by) !== 'AI Assistant',
                            }">
                            <i class="mr-2 fas fa-microchip-ai fa-xl" v-if="userName(activity.created_by) == 'AI Assistant'"></i>
                            <span v-html="parseComment(activity.details.comment)"></span>
                        </div>
                    </template>
                    <ul class="flex mt-2 text-xs">
                        <li class="mr-1 text-gray-500">{{ userName(activity.created_by) }}</li>
                        <li v-if="activity.type == 'comment'" class="text-gray-500">commented <tooltip :title="dt(activity.created_at)">{{ ago(activity.created_at) }}</tooltip></li>
                        <li v-else-if="activity.type == 'note'" class="text-gray-500">noted <tooltip :title="dt(activity.created_at)">{{ ago(activity.created_at) }}</tooltip></li>
                        <li v-if="activity.created_at != activity.updated_at" class="text-gray-500">, edited <tooltip :title="dt(activity.updated_at)">{{ ago(activity.updated_at) }}</tooltip></li>
                        <template v-if="canEditActivity(activity)">
                            <li class="ml-2 underline cursor-pointer hover:text-blue-600" @click="editActivity(activity)">Edit</li>
                            <li class="ml-2 underline cursor-pointer hover:text-blue-600" @click="deleteActivity(activity)">Delete</li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'status'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                    <div class="flex items-center text-sm text-gray-500 gap-x-1">
                        <span class="font-medium text-gray-900">{{ userName(activity.created_by) }}</span>
                        changed the status to
                        <span
                            :class="'inline-flex gap-x-1 items-center font-medium text-' + status(activity.details.status).color"
                        >
                            <i
                                :class="{
                                    [status(activity.details.status).color]: true,
                                    [status(activity.details.status).icon]: true
                                }"
                            ></i>
                            {{ status(activity.details.status).label }}
                        </span>
                        <tooltip :title="dt(activity.created_at)" class="ml-1 whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                    <div
                        v-if="activity.details.note"
                        class="max-w-full px-4 py-3 prose whitespace-pre-line border rounded-lg mt-2"
                        :class="{
                            'text-blue-600 border-blue-300 bg-blue-50': activity.details.status == 'new',
                            'text-orange-600 border-orange-300 bg-orange-50': activity.details.status == 'in-progress',
                            'text-red-600 border-red-300 bg-red-50': activity.details.status == 'need-more-information',
                            'text-violet-600 border-violet-300 bg-violet-50': activity.details.status == 'waiting-on-client',
                            'text-green-600 border-green-300 bg-green-50': activity.details.status == 'resolved',
                            'text-orange-600 border-orange-300 bg-orange-50': activity.details.status == 'reopened'
                        }">
                        <i class="mr-2 fas fa-microchip-ai fa-xl" v-if="userName(activity.created_by) == 'AI Assistant'"></i>
                        <span v-html="parseComment(activity.details.note)"></span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'priority'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                    <div class="flex items-center text-sm text-gray-500 gap-x-1">
                        <span class="font-medium text-gray-900">{{ userName(activity.created_by) }}</span>
                        changed the priority to
                        <span
                            :class="'inline-flex gap-x-1 items-center font-medium text-' + priority(activity.details.priority).color"
                        >
                            <i
                                :class="{
                                    [priority(activity.details.priority).color]: true,
                                    [priority(activity.details.priority).icon]: true
                                }"
                            ></i>
                            {{ priority(activity.details.priority).label }}
                        </span>
                        <tooltip :title="dt(activity.created_at)" class="ml-1 whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'meta'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                    <div class="flex items-center text-sm text-gray-500 gap-x-1">
                        <span class="font-medium text-gray-900">{{ userName(activity.created_by) }}</span>
                        updated the {{ Object.keys(activity.details)[0] }}
                        <tooltip :title="dt(activity.created_at)" class="ml-1 whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'trello_card'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                    <div class="text-sm text-gray-500">
                        <a href="#" class="font-medium text-gray-900">{{ userName(activity.created_by) }}</a>
                        created a <a :href="activity.details.trello_card_url" target="_blank" class="text-blue-500 hover:underline mr-1">Trello card</a>
                        <tooltip :title="dt(activity.created_at)" class="ml-1 whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'attachment'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                    <div class="flex items-center text-sm text-gray-500 gap-x-1">
                        <span class="font-medium text-gray-900">{{ userName(activity.created_by) }}</span>
                        <template v-if="activity.media && activity.media.length == 1">
                            added an attachment
                        </template>
                        <template v-else>
                            added {{ activity.media.length }} attachments
                        </template>
                        <tooltip :title="dt(activity.created_at)" class="whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'assigned'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                    <div class="text-sm text-gray-500">
                        <a href="#" class="font-medium text-gray-900">{{ userName(activity.created_by) }}</a>
                        <template v-if="activity.details.assigned_to">
                            assigned
                            <a href="#" class="font-medium text-gray-900">{{ userName(activity.details.assigned_to )}}</a>
                        </template>
                        <template v-else>
                            unassigned a user
                        </template>
                        <tooltip :title="dt(activity.created_at)" class="ml-1 whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'team'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                    <div class="text-sm text-gray-500">
                        <a href="#" class="font-medium text-gray-900">{{ userName(activity.created_by) }}</a>
                        <template v-if="activity.details.assigned_to">
                            assigned
                            <a href="#" class="font-medium text-gray-900">{{ teamName(activity.details.team) }}</a>
                        </template>
                        <template v-else>
                            unassigned a team
                        </template>
                        <tooltip :title="dt(activity.created_at)" class="ml-1 whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity.type == 'tagged'">
            <div class="relative flex items-start space-x-3">
                <div>
                    <div class="relative">
                        <img
                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                            :src="userAvatar(activity.created_by)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="flex-1 min-w-0 py-0">
                    <div class="text-sm leading-8 text-gray-500">
                        <span class="mr-0.5">
                            <a href="#" class="font-medium text-gray-900">{{ userName(activity.created_by) }}</a>
                            added tags
                        </span>
                        <span class="mr-0.5 inline-flex gap-x-2">
                            <span
                                v-for="tag in activity.details.tags"
                                :key="activity.id + '-' + tag"
                                class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                                :style="'background-color: ' + tagColor(tag) + '44;'"
                            >
                                <span class="absolute flex items-center justify-center flex-shrink-0">
                                    <span
                                        class="h-1.5 w-1.5 rounded-full"
                                        :style="'background-color: ' + tagColor(tag) + ';'"
                                        aria-hidden="true"
                                    ></span>
                                </span>
                                <span class="ml-3.5 font-medium text-gray-900">{{ tag }}</span>
                            </span>
                        </span>
                        <tooltip :title="dt(activity.created_at)" class="ml-1 whitespace-nowrap">{{ ago(activity.created_at) }}</tooltip>
                    </div>
                </div>
            </div>
        </div>
        <ul class="flex flex-wrap gap-4 ml-12" v-if="activity.media">
            <li v-for="media in activity.media" :key="media.id">
                <div class="relative">
                    <template v-for="(comment, idx) in media.data?.comments">
                        <span
                            :ref="'comment-' + media.id + '-' + idx"
                            v-if="media.data?.width && media.data?.height"
                            class="rounded-full h-5 w-5 border-2 boder-white absolute top-2 left-2 pulse text-[8px] text-white inline-flex items-center justify-center"
                            :class="{
                                'bg-blue-500': true,
                                'bg-red-500': false,
                            }"
                            :style="{
                                left: `${100*(comment.position.x / media.data.width)}%`,
                                top: `${100*(comment.position.y / media.data.height)}%`,
                            }"
                        >{{ idx + 1 }}</span>
                    </template>
                    <img
                        v-if="media.type && media.type.indexOf('image') >= 0"
                        @click="display(media)"
                        :src="asset(media.fullUrl)"
                        class="w-auto h-32 bg-white border border-gray-200 cursor-pointer rounded-xl"
                    />
                    <img
                        v-else-if="media.type && media.type.indexOf('video') >= 0 && media.data && media.data.thumbnail"
                        @click="display(media)"
                        :src="(media.data && 'thumbnail' in media.data) ? asset(media.data.thumbnail) : null"
                        class="w-auto h-32 bg-white border border-gray-200 cursor-pointer rounded-xl"
                    />
                    <a
                        v-else-if="media.type && media.type.indexOf('video') >= 0"
                        href="#"
                        @click.stop.prevent="display(media)"
                        class="rounded-xl border border-gray-200 h-32 aspect-[16/9] cursor-pointer bg-white block"
                    >
                        <video class="w-full pointer-events-none">
                            <source :src="media.fullUrl" />
                        </video>
                    </a>
                    <a
                        v-else
                        :href="media.fullUrl"
                        class="text-xs text-blue-500 underline hover:text-blue-700"
                    >{{  media.fileName }}</a>
                </div>
                <div class="prose">
                    <ol class="my-2">
                        <template v-for="(comment, idx) in media.data?.comments">
                            <li
                                class="text-xs text-gray-500 cursor-pointer hover:text-blue-500"
                                @mouseenter="hoverComment(media, idx, true)"
                                @mouseleave="hoverComment(media, idx, false)"
                            >
                                {{ comment.comment }}
                            </li>
                        </template>
                    </ol>
                </div>
            </li>
        </ul>
        <div v-if="lightbox">
            <div class="fixed bg-[#000A] top-0 left-0 w-screen h-screen z-50"></div>
            <div class="fixed top-0 left-0 h-screen w-screen z-[100]" @click="lightbox = null">
                <div
                    class="max-w-[85vw] max-h-[85vh] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                    <template v-for="(comment, idx) in lightbox.data?.comments">
                        <div
                            class="absolute z-50 cursor-pointer group"
                            :style="{
                                left: `${100*(comment.position.x / lightbox.data.width)}%`,
                                top: `${100*(comment.position.y / lightbox.data.height)}%`,
                            }"
                        >
                            <div
                                class="absolute left-6 bg-white text-xs py-2 px-3 shadow-lg rounded-xl min-w-[200px] max-w-[400px] hidden group-hover:block group-hover:z-50"
                            >{{ comment.comment }}</div>
                            <span
                                :ref="'comment-' + lightbox.id + '-' + idx"
                                v-if="lightbox.data?.width && lightbox.data?.height"
                                class="rounded-full h-5 w-5 border-2 boder-white pulse text-[8px] text-white inline-flex items-center justify-center"
                                :class="{
                                    'bg-blue-500': true,
                                    'bg-red-500': false,
                                }"
                            >{{ idx + 1 }}</span>
                        </div>
                    </template>
                    <video controls v-if="lightbox.type === 'video'">
                        <source :src="lightbox.fullUrl" />
                    </video>
                    <btn
                        v-else-if="lightbox.type === 'file'"
                        :href="lightbox.fullUrl"
                    >Download File</btn>
                    <img
                        v-else
                        :src="asset(lightbox.fullUrl)"
                    />
                </div>
                <ul
                    v-if="canEditActivity(activity)"
                    class="absolute bottom-0 flex items-center justify-center w-full gap-5 py-3 mt-2"
                >
                    <li>
                        <a href="#" class="text-gray-300 hover:text-red-400" @click.stop.prevent="deleteAttachment(lightbox.id)">
                            <i class="fa fa-trash fa-fw"></i>
                            Delete Attachment
                        </a>
                    </li>
                    <li>
                        <a href="#" class="text-white" @click.stop.prevent="closeLightbox(null)">
                            <i class="fa fa-times fa-fw"></i>
                            Close
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <template v-if="0">
            <div class="text-blue-500' text-orange-500' text-red-500' text-violet-500' text-green-500"></div>
        </template>
    </div>
</template>

<script>
import btn from '@/Common/btn.vue';
import tooltip from '@/Common/tooltip.vue';
import editor from '@/Common/editor.vue';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

export default {
    name: 'Activity',
    components: {
        btn, editor, tooltip
    },
    props: {
        auth: Object,
        activity: Object,
        showDetails: {
            type: Boolean,
            default: false
        },
        userAvatar: Function,
        userName: Function,
        userNames: Object,
        userEmail: Function,
        teamName: Function,
    },
    data() {
        return {
            editing: false,
            content: '',
            lightbox: null
        };
    },
    beforeMount() {
        document.addEventListener('keydown', this.closeLightbox);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.closeLightbox);
    },
    computed: {
        show() {
            if (this.showDetails) {
                return true;
            }
            if (['comment', 'note', 'attachment'].includes(this.activity.type)) {
                return true;
            }
            if (this.activity.type == 'status' && this.activity.details.note) {
                return true;
            }
            return false;
        }
    },
    methods: {
        safe(html) {
            return sanitizeHtml(html, {
                disallowedTagsMode: 'recursiveEscape',
                transformTags: {
                    'a': function(tagName, attribs) {
                        return {
                            tagName,
                            attribs: {
                                ...attribs,
                                target: "_blank"
                            }
                        };
                    }
                }
            });
        },
        hoverComment(media, idx, mouseOver) {
            this.$refs['comment-' + media.id + '-' + idx][0].classList.toggle('bg-blue-500', !mouseOver);
            this.$refs['comment-' + media.id + '-' + idx][0].classList.toggle('bg-red-500', mouseOver);
        },
        closeLightbox(event) {
            if (!event || event.keyCode == 27) {
                this.lightbox = null;
            }
        },
        asset (url) {
            if (url.match(/^http/)) {
                return url;
            }
            return window.Vapor.asset(url);
        },
        dt (value) {
            const date = moment.utc(value).local()
            if (date.isSame(moment(), 'day')) {
                return date.format('h:mma');
            } else if (date.isSame(moment(), 'year')) {
                return date.format('MMM Do, h:mma');
            }
            return date.format('MMMM Do YYYY, h:mma');
        },
        ago (value) {
            return moment.utc(value).fromNow();
        },
        status(status) {
            const statuses = {
                'new': {
                    label: 'New',
                    icon: 'fa-fw fad fa-sparkles',
                    color: 'blue-500',
                },
                'in-progress': {
                    label: 'In Progress',
                    icon: 'fa-fw fad fa-cogs',
                    color: 'orange-500',
                },
                'need-more-information': {
                    label: 'Need More Information',
                    icon: 'fa-fw fad fa-message-question',
                    color: 'red-500',
                },
                'waiting-on-client': {
                    label: 'Waiting on Client',
                    icon: 'fa-fw fad fa-user-clock',
                    color: 'violet-500',
                },
                'resolved': {
                    label: 'Resolved',
                    icon: 'fa-fw fad fa-check-circle',
                    color: 'green-500',
                },
                'reopened': {
                    label: 'Reopened',
                    icon: 'fa-fw fad fa-exclamation-circle',
                    color: 'orange-500',
                }
            }
            return status in statuses ? statuses[status] : status
        },
        priority(priority) {
            const priorities = {
                '1': {
                    label: 'None',
                    icon: 'fa-fw fad fa-face-sleeping',
                    color: 'gray-500',
                },
                '2': {
                    label: 'Low',
                    icon: 'fa-fw fad fa-face-thinking',
                    color: 'blue-500',
                },
                '3': {
                    label: 'Medium',
                    icon: 'fa-fw fad fa-face-smile',
                    color: 'green-500',
                },
                '4': {
                    label: 'High',
                    icon: 'fa-fw fad fa-face-anxious-sweat',
                    color: 'orange-500',
                },
                '5': {
                    label: 'Urgent',
                    icon: 'fa-fw fad fa-face-scream',
                    color: 'red-500',
                }
            }
            return priority in priorities ? priorities[priority] :priority
        },
        display(media) {
            this.lightbox = media;
        },
        cancelActivityEdit() {
            this.comment.id = "";
            this.comment.comment = "";
        },
        canEditActivity(activity) {
            return this.auth.user.permissions.includes('forge:admin') || activity.created_by.id === this.auth.user.id;
        },
        editActivity() {
            this.editing = true;
            this.content = this.activity.details.comment;
        },
        saveChanges() {
            this.$emit('update', {
                details: {
                    comment: this.content
                }
            })
            this.activity.updated_at = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            this.activity.details.comment = this.content;
            this.editing = false;
        },
        deleteAttachment(id) {
            if (confirm("Are you sure you want to remove this attachment?")) {
                this.$emit('delete-attachment', id)
                this.lightbox = null;
            }
        },
        deleteActivity() {
            this.$emit('delete')
        },
        parseComment(comment) {
            // replace {{ $userId }} with the user's name
            return this.safe(comment || '').replace(/{{\s*\$(.+)\s*}}/g, (match, userId) => {
                return `<span class="font-bold">${this.userNames[Number(userId)] || userId}</span>`;
            });
        },
    }
}
</script>

<style scoped>
.pulse {
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}
</style>
